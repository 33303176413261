import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Navigation from "Components/Layout/Navigation/Navigation";

import Dashboard from "Pages/Dashboard";
import Profile from "Pages/Profile";

import Footer from "Components/Layout/Footer/Footer";
import ScrollToTop from "Components/Layout/ScrollToTop/ScrollToTop";

import "./App.css";
import LoadingSpinner from "Components/Layout/UIElements/LoadingSpinner/LoadingSpinner";

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Navigation />
        <main>
          <Switch>
            <Route path="/" exact>
              <Dashboard />
            </Route>
            <Route path="/Profile" exact>
              <Profile />
            </Route>
            <Route path="/test" exact>
              <LoadingSpinner isLoading={true} />
            </Route>
            <Redirect to="/" />
          </Switch>
        </main>
        <Footer />
        <ScrollToTop showBelow={0} />
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
