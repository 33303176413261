import React, { useState } from "react";
import { useAuth } from "reactfire";
import { useUser } from 'services/firebase';

import "./Profile.css";

const ProfilePage = () => {
  const user = useUser();
  const auth = useAuth();

  const [displayName, setDisplayName] = useState(user.displayName ?? "");

  if (user !== null) {
    return (
      <div>
        <h2>User Info</h2>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                <input
                  value={displayName}
                  onChange={({ target: { value } }) => setDisplayName(value)}
                />
                <button
                  onClick={async () => {
                    user.update({
                      displayName: displayName,
                    });
                  }}
                >
                  Save Name
                </button>
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{user.email}</td>
            </tr>
          </tbody>
        </table>
        <button onClick={() => auth.signOut()}>Sign Out</button>
      </div>
    );
  } else {
    return <p>No user</p>;
  }
};

export default ProfilePage;