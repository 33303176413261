import React from "react";
import ReactEcharts from "echarts-for-react";

type ChartsProps = {
  title?: {
    text: string;
    left: string;
    textStyle?: {
      color?: any;
    };
  };
  series: {
    name: string;
    type: string;
    data: { name: string; value: number }[] | number[];
  }[];
  xAxis?: any;
  yAxis?: any;
  legend?: {
    orient: string;
    left: string;
    textStyle?: {
      color?: any;
    };
  };
  grid?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  tooltip?: { appendToBody?: boolean };
  className?: string;
  notMerge: boolean;
  lazyUpdate: boolean;
  theme: string;
  callback?: () => void;
};

const Charts: React.FunctionComponent<ChartsProps> = (props) => {
  let option: any = {
    title: props.title,
    legend: props.legend,
    series: props.series,
    grid: props.grid,
    xAxis: props.xAxis ?? { show: false },
    yAxis: props.yAxis ?? { show: false },
    tooltip: props.tooltip ?? { appendToBody: false },
  };

  return (
    <div className={props.className}>
      <ReactEcharts
        style={{
          height: "100%",
          width: "100%",
        }}
        option={option}
        notMerge={props.notMerge}
        lazyUpdate={props.lazyUpdate}
        theme={props.theme}
        onChartReady={props.callback}
        opts={{ renderer: "svg" }}
      />
    </div>
  );
};

export default Charts;
