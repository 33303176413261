import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

type LoadingSpinnerProps = {
  isLoading: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.appBar + 1,
    },
  })
);

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = (
  props
) => {
  const classes = useStyles();
  const nodeRef = React.useRef(null);

  return (
    <Backdrop ref={nodeRef} className={classes.backdrop} open={props.isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingSpinner;
