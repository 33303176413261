import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

type DisplayModalProps = {
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    size: {
      width: "80%",
    }
  })
);

const DisplayModal: React.FunctionComponent<DisplayModalProps> = (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.setModalState(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isModalOpen}>
        <>
          {props.children}
        </>
      </Fade>
    </Modal>
  );
};

export default DisplayModal;
