import React from "react";
import Grid from "@material-ui/core/Grid";

import ListItem from "@material-ui/core/ListItem";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import Charts from "../../Layout/UIElements/Charts/Charts";

type MyPortfolio_ItemProps = {
  onClick: (ticker: string) => void;
  ticker: string;
  xData: any[];
  yData: number[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    ticker: {
      fontSize: 20,
      textAlign: "center",
    },
    quote: {
      alignContent: "center",
    },
    price: {
      fontSize: 16,
    },
    change: {
      fontSize: 12,
    },
    positive: {
      color: "lightgreen",
    },
    negative: {
      color: "lightred",
    },
    chart: {
      height: "5rem",
    },
    hover: {
      "&:hover": {
        backgroundColor: "grey",
      },
    },
  })
);

const MyPortfolio_Item: React.FunctionComponent<MyPortfolio_ItemProps> = (
  props
) => {
  const classes = useStyles();

  const price = 123.45;
  const change = 1.1;

  return (
    <ListItem>
      <Card className={`${classes.root} ${classes.hover}`} variant="outlined">
        <Grid container onClick={() => props.onClick(props.ticker)}>
          <Grid item xs>
            <div className={classes.ticker}>{props.ticker}</div>
            <Grid container className={classes.quote}>
              <Grid item xs>
                <Typography className={classes.price}>{`$${price}`}</Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  className={`${classes.change} ${
                    change > 0
                      ? classes.positive
                      : change < 0
                      ? classes.negative
                      : null
                  }`}
                >
                  {change > 0
                    ? `+$${change}`
                    : change < 0
                    ? `-$${change}`
                    : null}
                  <br />
                  {change > 0
                    ? `+${((change / price) * 100.0).toFixed(2)}%`
                    : change < 0
                    ? `-${((change / price) * 100.0).toFixed(2)}%F`
                    : null}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Charts
              className={classes.chart}
              xAxis={{
                axisLabel: false,
                type: "category",
                boundaryGap: false,
                data: props.xData,
              }}
              yAxis={{ axisLabel: false }}
              series={[
                {
                  name: props.ticker,
                  type: "line",
                  data: props.yData,
                },
              ]}
              grid={{
                left: 15,
                top: 15,
                right: 15,
                bottom: 15,
              }}
              tooltip={{
                appendToBody: true,
              }}
              notMerge={false}
              lazyUpdate={false}
              theme="default"
            />
          </Grid>
        </Grid>
      </Card>
    </ListItem>
  );
};

export default MyPortfolio_Item;
