import firebase from "firebase/app";
import { UserDoc } from "model/user";

import {
  useFirestore,
  useFirestoreDocData,
  useUser as useUserReactfire,
} from "reactfire";

export const firebaseConfig = {
  apiKey: "AIzaSyCc7wHD45E4FeTzva3KiaBByw2uyNRfOTA",
  authDomain: "portfolio-80fbd.firebaseapp.com",
  databaseURL: "https://portfolio-80fbd.firebaseio.com",
  projectId: "portfolio-80fbd",
  storageBucket: "portfolio-80fbd.appspot.com",
  messagingSenderId: "391535153125",
  appId: "1:391535153125:web:aba527638f55578686290c",
  measurementId: "G-MYLMNNFWHK",
};

export function useUser() {
  const authUser: firebase.User = useUserReactfire();
  const userRef = useFirestore().collection("users").doc(authUser.uid);
  const userData: UserDoc = useFirestoreDocData(userRef);
  return {
    ...userData,
    email: authUser.email!,
    uid: authUser.uid,
    update: (fields: Partial<UserDoc>) => userRef.update(fields),
  };
}
