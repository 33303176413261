import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";

type ScrollToTopProps = {
  showBelow: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

const ScrollToTop: React.FunctionComponent<ScrollToTopProps> = (props) => {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: props.showBelow,
  });

  const handleClick = () => {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
  };

  const button = (
    <div onClick={handleClick} role="presentation" className={classes.root}>
      <Fab color="default" size="medium" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
    </div>
  );

  return <Zoom in={trigger}>{button}</Zoom>;
};

export default ScrollToTop;
