import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { AuthCheck, FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "services/firebase";
import LoginPage from "Pages/Login";
import LoadingSpinner from "Components/Layout/UIElements/LoadingSpinner/LoadingSpinner";

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense fallback={<LoadingSpinner isLoading />}>
        <AuthCheck fallback={<LoginPage />}>
          <App />
        </AuthCheck>
      </Suspense>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
