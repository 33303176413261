import React from "react";

import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Charts from "../../Layout/UIElements/Charts/Charts";

type MainDashboardProps = {
  componentWidth: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  gain: {
    color: theme.palette.success.dark,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  chart: {
    height: "15rem",
  },
}));

const MainDashboard: React.FunctionComponent<MainDashboardProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const total = 123456789;
  const gain = 100045;
  const percentage = ((gain / total) * 100.0).toFixed(4);

  return (
    <div
      className={`${classes.root} ${classes.content} ${props.componentWidth}`}
      color="inherit"
    >
      <div className={classes.toolbar} />
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h5" noWrap>
                  TOTAL VALUE: $
                  {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </Grid>
              <Grid item xs>
                {/* color={gain > 0 ? theme.palette.success : gain < 0 ? theme.palette.error : "inherit"} */}
                <div color="success">
                  <Typography>${gain}</Typography>
                  <Divider />
                  <Typography>{percentage}%</Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Charts
            className={classes.chart}
            title={{
              text: "Portfolio Diversity",
              left: "center",
              textStyle: { color: theme.palette.text.primary },
            }}
            legend={{
              orient: "vertical",
              left: "left",
              textStyle: { color: theme.palette.text.secondary },
            }}
            series={[
              {
                name: "My Portfolio",
                type: "pie",
                data: [
                  {
                    name: "AAPL",
                    value: 20,
                  },
                  {
                    name: "AMZN",
                    value: 40,
                  },
                  {
                    name: "TSLA",
                    value: 40,
                  },
                ],
              },
            ]}
            notMerge={false}
            lazyUpdate={false}
            theme="default"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MainDashboard;
