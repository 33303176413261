import React from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
//import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

type NewsProps = {
  componentWidth: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const News: React.FunctionComponent<NewsProps> = (props) => {
  const TEST_DATA = [
    {
      title: "Test 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pretium elit ac faucibus gravida. Cras sit amet eros ut mauris porta ultricies. Quisque sed enim turpis. Suspendisse et hendrerit nisi. Mauris dapibus risus diam, vitae fermentum nisl posuere id. Curabitur sit amet aliquet augue, semper maximus leo. Nullam ac pretium quam. Etiam venenatis lacinia nisl ut tempor. Donec dapibus vitae mi eget dapibus. Mauris at rhoncus sapien. Proin suscipit leo at felis ornare accumsan.",
    },
    {
      title: "Test 2",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pretium elit ac faucibus gravida. Cras sit amet eros ut mauris porta ultricies. Quisque sed enim turpis. Suspendisse et hendrerit nisi. Mauris dapibus risus diam, vitae fermentum nisl posuere id. Curabitur sit amet aliquet augue, semper maximus leo. Nullam ac pretium quam. Etiam venenatis lacinia nisl ut tempor. Donec dapibus vitae mi eget dapibus. Mauris at rhoncus sapien. Proin suscipit leo at felis ornare accumsan.",
    },
    {
      title: "Test 3",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pretium elit ac faucibus gravida. Cras sit amet eros ut mauris porta ultricies. Quisque sed enim turpis. Suspendisse et hendrerit nisi. Mauris dapibus risus diam, vitae fermentum nisl posuere id. Curabitur sit amet aliquet augue, semper maximus leo. Nullam ac pretium quam. Etiam venenatis lacinia nisl ut tempor. Donec dapibus vitae mi eget dapibus. Mauris at rhoncus sapien. Proin suscipit leo at felis ornare accumsan.",
    },
    {
      title: "Test 4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pretium elit ac faucibus gravida. Cras sit amet eros ut mauris porta ultricies. Quisque sed enim turpis. Suspendisse et hendrerit nisi. Mauris dapibus risus diam, vitae fermentum nisl posuere id. Curabitur sit amet aliquet augue, semper maximus leo. Nullam ac pretium quam. Etiam venenatis lacinia nisl ut tempor. Donec dapibus vitae mi eget dapibus. Mauris at rhoncus sapien. Proin suscipit leo at felis ornare accumsan.",
    },
  ];

  const classes = useStyles();

  return (
    <div
      className={`${classes.root} ${classes.content} ${props.componentWidth}`}
    >
      {TEST_DATA.map((item, index) => (
        <Grid container key={index}>
          <Typography variant="h5">{item.title}</Typography>
          <Typography>{item.description}</Typography>
          <Divider />
        </Grid>
      ))}
    </div>
  );
};

export default News;
