import React from "react";

import Carousel from "react-material-ui-carousel";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";

import MyPortfolioItem from "./MyPortfolio-Item";
import Modal from "../../Layout/UIElements/Modal/Modal";
import Chart from "../../Layout/UIElements/Charts/Charts";

type MyPortfolioProps = {
  componentWidth: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    title: {
      padding: theme.spacing(2),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    modal: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "70%",
      height: "90%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    modalChartSection: {
      // border: "2px solid #000",
      width: "80%",
      margin: "auto",
    },
    chart: {
      height: "10rem",
    },
    chartButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    chartDetail: {
      margin: "auto",
      width: "100%",
    },
    chartDetailItem: {
      margin: theme.spacing(1),
    },
    modalCarousel: {
      overflowY: "auto",
      margin: theme.spacing(2),
      flexGrow: 1,
    },
  })
);

const TICKER_DATA = {
  price: 123.45,
  xData: ["9:00", "10:00", "11:00", "12:00", "1:00", "2:00", "3:00", "4:00"],
  yData: [120, 132, 101, 134, 110, 230, 210, 135],
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pretium elit ac faucibus gravida. Cras sit amet eros ut mauris porta ultricies. Quisque sed enim turpis. Suspendisse et hendrerit nisi. Mauris dapibus risus diam, vitae fermentum nisl posuere id. Curabitur sit amet aliquet augue, semper maximus leo. Nullam ac pretium quam. Etiam venenatis lacinia nisl ut tempor. Donec dapibus vitae mi eget dapibus. Mauris at rhoncus sapien. Proin suscipit leo at felis ornare accumsan.",
};

const MyPortfolio: React.FunctionComponent<MyPortfolioProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isModalOpen, setModalState] = React.useState(false);
  const [modalTicker, setModalTicker] = React.useState("");

  const handleModalState = (ticker: string) => {
    setModalTicker(ticker);

    setModalState(!isModalOpen);
  };

  const handleChartButtonClick = (days: number) => {
    console.log(days);
  };

  const header = "My Portfolio";

  const TEST_DATA = [
    {
      ticker: "AAPL",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 132, 101, 134, 110, 230, 210, 135],
    },
    {
      ticker: "TSLA",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [1000, 1020, 990, 1015, 1040, 1080, 2000],
    },
    {
      ticker: "AMZN",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 121, 118, 128, 119, 124, 130, 135],
    },
    {
      ticker: "GOOG",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [240, 245, 250, 235, 240, 238, 242, 250],
    },
    {
      ticker: "AAPL",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 132, 101, 134, 110, 230, 210, 135],
    },
    {
      ticker: "TSLA",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [1000, 1020, 990, 1015, 1040, 1080, 2000],
    },
    {
      ticker: "AMZN",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 121, 118, 128, 119, 124, 130, 135],
    },
    {
      ticker: "GOOG",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [240, 245, 250, 235, 240, 238, 242, 250],
    },
    {
      ticker: "AAPL",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 132, 101, 134, 110, 230, 210, 135],
    },
    {
      ticker: "TSLA",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [1000, 1020, 990, 1015, 1040, 1080, 2000],
    },
    {
      ticker: "AMZN",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 121, 118, 128, 119, 124, 130, 135],
    },
    {
      ticker: "GOOG",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [240, 245, 250, 235, 240, 238, 242, 250],
    },
    {
      ticker: "AAPL",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 132, 101, 134, 110, 230, 210, 135],
    },
    {
      ticker: "TSLA",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [1000, 1020, 990, 1015, 1040, 1080, 2000],
    },
    {
      ticker: "AMZN",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 121, 118, 128, 119, 124, 130, 135],
    },
    {
      ticker: "GOOG",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [240, 245, 250, 235, 240, 238, 242, 250],
    },
    {
      ticker: "AAPL",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 132, 101, 134, 110, 230, 210, 135],
    },
    {
      ticker: "TSLA",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [1000, 1020, 990, 1015, 1040, 1080, 2000],
    },
    {
      ticker: "AMZN",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [120, 121, 118, 128, 119, 124, 130, 135],
    },
    {
      ticker: "GOOG",
      xData: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
      ],
      yData: [240, 245, 250, 235, 240, 238, 242, 250],
    },
  ];

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Typography variant="h5" className={classes.title}>
        {header}
      </Typography>
      <Divider />
      <List>
        {TEST_DATA.map((item, index) => (
          <MyPortfolioItem
            onClick={(ticker) => handleModalState(ticker)}
            key={index}
            ticker={item.ticker}
            xData={item.xData}
            yData={item.yData}
          />
        ))}
      </List>
    </div>
  );

  const modalChartsTimeSettings = [
    { text: "1D", value: 1 },
    { text: "1W", value: 7 },
    { text: "1M", value: 30 },
    { text: "3M", value: 90 },
    { text: "1Y", value: 365 },
    { text: "3Y", value: 1095 },
    { text: "MAX", value: 2000 },
  ];

  const modalChartDetails = [
    { text: "Prev Open/Close", value: "81.65/81.34" },
    { text: "Volume/Average Vol", value: "0.1M/0.1M\nAverage" },
    { text: "Day High/Low", value: "81.65/80.80" },
    { text: "52 Week High/Low", value: "83.24/56.50" },
    { text: "Market Cap/PE Ratio", value: "3.4B/25.90" },
    { text: "Quarterly Dividend/Yield", value: "0.27/1.41%" },
    { text: "Dividend Pay Date", value: "Oct 7 2020" },
    { text: "Ex-Dividend Date", value: "Sep 29 2020" },
    { text: "Total Net Assets", value: "3.3B" },
    { text: "Category", value: "US Equity" },
  ];

  const modalCarouselItems = [
    {
      title: "News 1",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ipsum magna, posuere eu ante at, ornare tincidunt est. Aenean ultricies dui in iaculis accumsan. Donec iaculis, dui in egestas pulvinar, lectus lectus bibendum risus, sed semper dui nunc eget libero. Phasellus placerat leo dui. Praesent et semper nunc, id consequat tellus. Integer libero nunc, ultrices et congue at, interdum vitae nunc. In sed euismod ex. Nam tincidunt sollicitudin nisi convallis pulvinar.",
    },
    {
      title: "News 2",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ipsum magna, posuere eu ante at, ornare tincidunt est. Aenean ultricies dui in iaculis accumsan. Donec iaculis, dui in egestas pulvinar, lectus lectus bibendum risus, sed semper dui nunc eget libero. Phasellus placerat leo dui. Praesent et semper nunc, id consequat tellus. Integer libero nunc, ultrices et congue at, interdum vitae nunc. In sed euismod ex. Nam tincidunt sollicitudin nisi convallis pulvinar.",
    },
    {
      title: "News 3",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ipsum magna, posuere eu ante at, ornare tincidunt est. Aenean ultricies dui in iaculis accumsan. Donec iaculis, dui in egestas pulvinar, lectus lectus bibendum risus, sed semper dui nunc eget libero. Phasellus placerat leo dui. Praesent et semper nunc, id consequat tellus. Integer libero nunc, ultrices et congue at, interdum vitae nunc. In sed euismod ex. Nam tincidunt sollicitudin nisi convallis pulvinar.",
    },
    {
      title: "News 4",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ipsum magna, posuere eu ante at, ornare tincidunt est. Aenean ultricies dui in iaculis accumsan. Donec iaculis, dui in egestas pulvinar, lectus lectus bibendum risus, sed semper dui nunc eget libero. Phasellus placerat leo dui. Praesent et semper nunc, id consequat tellus. Integer libero nunc, ultrices et congue at, interdum vitae nunc. In sed euismod ex. Nam tincidunt sollicitudin nisi convallis pulvinar.",
    },
  ];

  const modal = (
    <div className={classes.modal}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h3">{modalTicker}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h3">{TICKER_DATA.price}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">$123.12</Typography>
          <Divider />
          <Typography variant="h6">12.5%</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid className={classes.modalChartSection} container>
        <Grid item xs={12}>
          <Chart
            className={classes.chart}
            xAxis={{
              axisLabel: {
                interval: 0,
                textStyle: { color: theme.palette.text.primary },
              },
              type: "category",
              boundaryGap: true,
              data: TICKER_DATA.xData,
            }}
            yAxis={{
              axisLabel: {
                interval: 0,
                textStyle: { color: theme.palette.text.primary },
              },
              type: "value",
            }}
            series={[
              {
                name: modalTicker,
                type: "line",
                data: TICKER_DATA.yData,
              },
            ]}
            grid={{
              left: 40,
              top: 20,
              right: 40,
              bottom: 20,
            }}
            notMerge={false}
            lazyUpdate={false}
            theme="default"
          />
          <div className={classes.chartButton}>
            {modalChartsTimeSettings.map((item, index) => (
              <Button
                key={index}
                onClick={() => handleChartButtonClick(item.value)}
              >
                {item.text}
              </Button>
            ))}
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <div className={classes.chartDetail}>
          {modalChartDetails.map((item, index) => (
            <Paper key={index} className={classes.chartDetailItem}>
              <Grid container>
                <Grid item xs={6}>
                  {item.text}
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {item.value}
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
      </Grid>
      <Grid container>
        <Carousel interval={8000}>
          {modalCarouselItems.map((item, index) => (
            <Paper key={index} className={classes.modalCarousel}>
              <Typography variant="h4">{item.title}</Typography>
              <Divider />
              <Typography>{item.text}</Typography>
            </Paper>
          ))}
        </Carousel>
      </Grid>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={props.componentWidth} aria-label={header}>
        <Drawer
          classes={{
            paper: props.componentWidth,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </nav>
      <Modal setModalState={setModalState} isModalOpen={isModalOpen}>
        {modal}
      </Modal>
    </div>
  );
};

export default MyPortfolio;
