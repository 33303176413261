import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import MyPortfolio from "../Components/Dashboard/MyPortfolio/MyPortfolio";
import MainDashboard from "../Components/Dashboard/MainDashboard/MainDashboard";
import News from "../Components/Dashboard/News/News";

const drawerWidth = "22.5%";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    portfolioDrawerWidth: {
      width: drawerWidth,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    portfolioContentWidth: {
      marginLeft: drawerWidth,
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },
    },
  })
);

const Dashboard = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <MyPortfolio componentWidth={classes.portfolioDrawerWidth} />
      <MainDashboard componentWidth={classes.portfolioContentWidth} />
      <News componentWidth={classes.portfolioContentWidth} />
    </React.Fragment>
  );
};

export default Dashboard;
