import React from "react";

//import { auth } from "firebase";
import { auth } from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useFirebaseApp } from "reactfire";

//import "./Login.css";

const LoginPage = () => {
  const app = useFirebaseApp();
  const uiConfig = {
    signInSuccessUrl: "/test",
    signInOptions: [auth.EmailAuthProvider.PROVIDER_ID],
  };

  return (
    <div>
      <h1>Log in</h1>
      <p>Dev account:</p>
      <p>email: test@stonks.sachy.co</p>
      <p>password: password</p>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={app.auth()} />
    </div>
  );
};
export default LoginPage;